<template>
    <div id="container"></div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { apiPowerBiGetEmbedInfo, apiPowerBiRlc } from "@/request/api";
import { onBeforeMount, reactive, nextTick } from "vue";
import * as pbi from "powerbi-client";
import useTabHeartbeat from "./useTabHeartbeat";
import useSlicerState from "./useSlicerState";
import { cloneDeep } from "loadsh";
import useTokenHeartbeat from "./useTokenHeartbeat";

const $route = useRoute();

const state = reactive({
    reportConfigId: $route.query.reportId,
    info: {},
    filters: [],
});
const report = ref(null); // powerbi报告实例

// token续期心跳
const { handleTokenContinue } = useTokenHeartbeat();

// tab心跳
const { handleHeartbeat, params: tabParams } = useTabHeartbeat();

// 记录选择参数
const { getSlicerStateFn } = useSlicerState(report, tabParams);

// 获取信息
async function getInfo() {
    const { data } = await apiPowerBiGetEmbedInfo({ reportConfigId: state.reportConfigId });
    return JSON.parse(data || "{}");
}

// 获取过滤器
async function getRlc() {
    let { data } = await apiPowerBiRlc({ reportConfigId: state.reportConfigId });
    data = data || [];
    data = data.map((item) => {
        item["$schema"] = "http://powerbi.com/product/schema#basic";
        item["filterType"] = pbi.models.FilterType.Basic; //定义筛选器的类型
        item["requireSingleSelection"] = true; //是否可以多选
        return item;
    });
    return data;
}

// 获取数据
function getData() {
    Promise.all([getInfo(), getRlc()]).then((res) => {
        state.info = res[0];
        state.filters = res[1];
        nextTick(initPowerBi);
    });
}

// 初始化powerbi
function initPowerBi() {
    const permissions = pbi.models.Permissions.All;
    // 定义过滤器对象
    // const basicFilter = {
    //     $schema: "http://powerbi.com/product/schema#basic",// 定义筛选器的架构  五种
    //     target: { table: "shop_canguan", column: "province" },
    //     operator: "In",// 对于基本筛选器，运算符可以是下列选项之一："In" | "NotIn" | "All"
    //     values: ['北京'],// 筛选器的值数组，所有值都需要具有相同的类型
    //     filterType: pbi.models.FilterType.BasicFilter,//定义筛选器的类型
    //     requireSingleSelection: true,// 是否可以多选
    // };
    const config = {
        type: "report",
        tokenType: pbi.models.TokenType.Embed,
        accessToken: state.info.embedToken,
        embedUrl: state.info.embedReports[0].embedUrl,
        permissions: permissions,
        // hostname: "https://app.powerbi.cn",
        filters: cloneDeep(state.filters), // 传递过滤器对象
        settings: {
            filterPaneEnabled: state.info.showFilters === "Y" ? true : false, // 是否显示筛选器
            // filterPaneEnabled: true,// 是否显示筛选器
            navContentPaneEnabled: true,
        },
    };
    window["tokenExpiry"] = state.info.tokenExpiry;
    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    const reportContainer = document.getElementById("container");
    report.value = powerbi.embed(reportContainer, config);
    // report.value.off("loaded");
    // report.value.off("rendered");
    //visualClicked
    // report.value.on("visualClicked", (e) => {
    //     if (e.detail.visual.type.startsWith('textFilter')) {
    //         getSlicerStateFn();// 获取切片器状态
    //     }
    // });
    // 监听tab切换
    report.value.on("pageChanged", (e) => {
        handleHeartbeat(e); // 处理心跳
        handleTokenContinue(); // 续期token
    });
    // 监听数据选择变化
    report.value.on("dataSelected", () => {
        getSlicerStateFn(); // 获取切片器状态
        handleTokenContinue(); // 续期token
    });
    report.value.on("error", () => {});
}

onBeforeMount(() => {
    getData();
    // 设置title
    document.title = $route.query.name;
});
</script>

<style lang="scss" scoped>
#container {
    width: 100%;
    height: 100vh;
}
</style>
