import { apiReportLogAdd } from '@/request/api';

export default (report, tabParams) => {

    async function getSlicerStateFn() {
        const res = [];
        //获取所有页面
        const pages = await report.value.getPages();
        // 获取当前选中页面
        const pageWithSlicer = pages.filter(function (page) {
            return page.isActive;
        })[0];
        // 获取页面组件
        let visuals = await pageWithSlicer.getVisuals();
        // 筛选需要的组件
        visuals = visuals.filter(function (visual) {
            const needs = ['slicer', 'tableEx', 'textbox'];
            return needs.includes(visual.type);
        });
        // 获取切片器状态
        for (let i = 0; i < visuals.length; i++) {
            // 切片器-slicer
            if (visuals[i].type === 'slicer') {
                const s = await visuals[i].getSlicerState();
                if (s.filters?.length) {
                    res.push({
                        title: visuals[i].title,
                        table: s.targets[0]?.table,
                        column: s.targets[0]?.column,
                        operator: s.filters[0]?.operator,
                        values: s.filters[0]?.values || [],
                    });
                }
            }
            // textbox
            // if (visuals[i].type === 'textbox') {
            // console.log(visuals[i])
            // const s = await visuals[i].clone();
            // console.log('clone', await visuals[i].clone())
            // console.log('exportData', await visuals[i].exportData())
            // console.log('getFilters', await visuals[i].page.getFilters())
            // console.log('getSlicerState', await visuals[i].getSlicerState())
            // if (s.filters?.length) {
            //     res.push({
            //         title: visuals[i].title,
            //         column: s.targets[0]?.column,
            //         operator: s.filters[0]?.operator,
            //         values: s.filters[0]?.values || [],
            //     });
            // }
            // }
        }
        res?.length && send(JSON.stringify(res));
    }

    // 发送请求
    function send(param) {
        const params = { ...tabParams, param };
        apiReportLogAdd(params);
    }

    return { getSlicerStateFn }
}