import { reactive } from 'vue';
import { onBeforeUnmount } from 'vue';
import { apiReportLogAdd } from '@/request/api';
import { useRoute } from 'vue-router';

export default () => {

    const $route = useRoute();
    let timer = null;
    const DURATION = 1000 * 60;// 心跳间隔时长
    const params = reactive({
        title: $route.meta.title,
        path: $route.path,
        reportId: $route.query?.reportId || null,// 报告id
    })

    function handleHeartbeat(e) {
        if (timer) {
            clearInterval(timer);
            timer = null;
        }
        if ($route.path !== '/powerbi') return;
        params.title = $route.meta.title + ':' + e.detail.newPage.displayName;
        apiReportLogAdd(params);
        timer = setInterval(() => {
            apiReportLogAdd(params);
        }, DURATION)
    }

    onBeforeUnmount(() => {
        clearInterval(timer);
        timer = null;
    })

    return { handleHeartbeat, params }
}